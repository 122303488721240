import { L } from './abpUtility'
import {
  ExcelIcon,
  ImageIcon,
  OtherFileIcon,
  PdfIcon,
  WordIcon,
  PowerPointIcon
} from '@components/Icon'

export const AppConfiguration = {
  appBaseUrl: '',
  remoteServiceBaseUrl: '',
  googleMapKey: '',
  appLayoutConfig: {} as any
}

export const validateStatus = {
  validating: 'validating',
  success: 'success',
  error: 'error',
  warning: 'warning'
}
export const keySyncfusion =
  'Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfRFxgSXtRdEBrUHdXcw==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jT39QdkBhW3pXcHRQRQ==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXhSdUVmW31beXRXRWM=;MjQwMTE3MUAzMjMxMmUzMDJlMzBWcml5T3JFdkRac1o1cnRPYnRHQ3ozaEZvZlEzOFlxSFpyVGhBLzBQQ3pJPQ==;MjQwMTE3MkAzMjMxMmUzMDJlMzBUckg5NjlJaXpaeG1LQm52MFN5SzFra3hvOFVueVlFWUsraDNKTE5pd1hJPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Vd0ZjXH1cdX1UR2Nf;MjQwMTE3NEAzMjMxMmUzMDJlMzBjR3NEUGdBbnpEaUt3NXJTQVpBMHZqWnJ4U0lDUVBCdlVhUS9Vb1J3UTMwPQ==;MjQwMTE3NUAzMjMxMmUzMDJlMzBqNTRTVDdWQVhOeCt0eEdNendTYUFSSXE2M3EyQktwZkRGZ21oL2RLY0tzPQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXhSdUVmW31beXZVQWM=;MjQwMTE3N0AzMjMxMmUzMDJlMzBhVGlYV1ZGcGs2Z09kTkNNV3ZlUEYzODYvOEdkWFFpc21FcnVTQ2NiVG5ZPQ==;MjQwMTE3OEAzMjMxMmUzMDJlMzBpNnpqYVpiTUFiQ2F2MWZyUit5dGcwSVdLZC9DcXhLbHlIbUNYSmFPV0k4PQ==;MjQwMTE3OUAzMjMxMmUzMDJlMzBjR3NEUGdBbnpEaUt3NXJTQVpBMHZqWnJ4U0lDUVBCdlVhUS9Vb1J3UTMwPQ=='

const AppConsts = {
  title: 'Easy Life - Quản Lý Tòa Nhà',
  align: {
    right: 'right' as const,
    left: 'left' as const,
    center: 'center' as const
  },
  dataType: {
    string: 'string' as const,
    number: 'number' as const,
    boolean: 'boolean' as const,
    method: 'method' as const,
    regexp: 'regexp' as const,
    integer: 'integer' as const,
    float: 'float' as const,
    object: 'object' as const,
    enum: 'enum' as const,
    date: 'date' as const,
    url: 'url' as const,
    hex: 'hex' as const,
    email: 'email' as const
  },
  formVerticalLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 }
    }
  },
  formHorizontalLayout: {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 6 },
      xl: { span: 6 },
      xxl: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 18 },
      xl: { span: 18 },
      xxl: { span: 20 }
    }
  },
  projectCategoryTarget: {
    unitType: 'UNITTYPE',
    unitStatus: 'UNITSTATUS',
    memberRole: 'MEMBERROLE',
    memberType: 'MEMBERTYPE'
  },
  activeStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return L('ALL')
      }
    },
    {
      name: 'Active',
      value: 'true',
      get label() {
        return L('ACTIVE')
      }
    },
    {
      name: 'Inactive',
      value: 'false',
      get label() {
        return L('INACTIVE')
      }
    }
  ],
  activeRefundable: [
    {
      name: 'Yes',
      value: 'true',
      get label() {
        return L('REFUND_YES')
      }
    },
    {
      name: 'No',
      value: 'false',
      get label() {
        return L('REFUND_NO')
      }
    }
  ],

  expiredOptions: [
    {
      name: 'Include Expired',
      value: 'true',
      get label() {
        return L('INCLUDE_EXPIRED')
      }
    },
    {
      name: 'Exclude Expired',
      value: 'false',
      get label() {
        return L('EXCLUDE_EXPIRED')
      }
    }
  ],
  genders: [
    { name: 'GENDER_MALE', value: true },
    { name: 'GENDER_FEMALE', value: false },
    { name: 'GENDER_OTHER', value: null }
  ],
  bookingTimes: [
    {
      get name() {
        return L('DAY')
      },
      value: 'DAY'
    },
    {
      get name() {
        return L('WEEK')
      },
      value: 'WEEK'
    },
    {
      get name() {
        return L('MONTH')
      },
      value: 'MONTH'
    }
  ],
  bookingFutureTypes: [
    {
      value: 'CURRENT',
      get label() {
        return L('CURRENT')
      }
    },
    {
      value: 'CURRENT_AND_NEXT',
      get label() {
        return L('CURRENT_AND_NEXT')
      }
    },
    {
      value: 'NEXT',
      get label() {
        return L('NEXT')
      }
    }
  ],
  bookingDates: [
    {
      numNextValidDate: 'ALL_DAY',
      value: 'ALL_DAY',
      get label() {
        return L('ALL_DAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 0
    },
    {
      numNextValidDate: 'MONDAY',
      value: 'MONDAY',
      get label() {
        return L('MONDAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 1
    },
    {
      numNextValidDate: 'TUESDAY',
      value: 'TUESDAY',
      get label() {
        return L('TUESDAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 2
    },
    {
      numNextValidDate: 'WEDNESDAY',
      value: 'WEDNESDAY',
      get label() {
        return L('WEDNESDAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 3
    },
    {
      numNextValidDate: 'THURSDAY',
      value: 'THURSDAY',
      get label() {
        return L('THURSDAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 4
    },
    {
      numNextValidDate: 'FRIDAY',
      value: 'FRIDAY',
      get label() {
        return L('FRIDAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 5
    },
    {
      numNextValidDate: 'SATURDAY',
      value: 'SATURDAY',
      get label() {
        return L('SATURDAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 6
    },
    {
      numNextValidDate: 'SUNDAY',
      value: 'SUNDAY',
      get label() {
        return L('SUNDAY')
      },
      isAnyTime: true,
      daySelected: true,
      order: 7
    }
  ],
  reservationStatus: {
    requested: 'REQUESTED',
    approved: 'APPROVED'
  },
  amenityStatus: {
    requested: 'REQUESTED',
    approved: 'APPROVED'
  },
  userManagement: {
    defaultAdminUserName: 'admin'
  },
  localization: {
    defaultLocalizationSourceName: 'WebLabel',
    sourceWebNotification: 'WebNotification',
    sourceWebError: 'WebError',
    sourceWebMainMenu: 'WebMainMenu',
    sourceWebCategory: 'WebCategory'
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token',
    projectId: 'projectId',
    projectPictureUrl: 'projectPictureUrl',
    userType: '2',
    unitDisplayName: ''
  },
  validate: {
    maxNumber: 999999999999
  },
  masterDataTargets: {
    WORK_ORDER_TYPE: 'WorkOrderType',
    UNIT_TYPE: 'UnitType',
    UNIT_STATUS: 'UnitStatus',
    RESIDENT_TYPE: 'ResidentType',
    RESIDENT_ROLE: 'ResidentRole'
  },
  notificationTypes: {
    all: 0,
    sms: 1,
    email: 2,
    inApp: 3
  },
  announcementTypes: {
    email_inApp: 0,
    email: 1,
    inApp: 2,
    sms: 3
  },
  announcementTypeKeys: {
    0: 'EMAIL_INAPP',
    1: 'EMAIL', //(allow HTML)
    2: 'INAPP',
    3: 'SMS'
  },

  announcementStatus: {
    readyForPublish: 1,
    sending: 2,
    completed: 3,
    failed: 4
  },
  announcementStatusKeys: {
    0: 'ANNOUNCEMENT_STATUS_PROCESSING',
    1: 'ANNOUNCEMENT_STATUS_READY_FOR_PUBLISH',
    2: 'ANNOUNCEMENT_STATUS_SENDING',
    3: 'ANNOUNCEMENT_STATUS_COMPLETED',
    4: 'ANNOUNCEMENT_STATUS_FAILED'
  },
  monthNamesShort: [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ],
  timeUnits: {
    hours: 'HOURS',
    days: 'DAYS',
    minutes: 'MINUTES'
  },

  feeSourceGroup: {
    feeManagement: 'FeeStatement',
    feeReservation: 'FeeReservation', // For both Service & Deposit
    feeReservationService: 'FeeReservationService',
    feeReservationDeposit: 'FeeReservationDeposit'
  },
  feePaymentStatus: {
    unPaid: 1,
    paid: 2,
    refund: 3
  },

  feeTypes: {
    bookingDeposit: 14
  },

  dayOfWeek: [
    {
      value: 0,
      name: 'Sunday'
    },
    {
      value: 1,
      name: 'Monday'
    },
    {
      value: 2,
      name: 'Tuesday'
    },
    {
      value: 3,
      name: 'Wednesday'
    },
    {
      value: 4,
      name: 'Thursday'
    },
    {
      value: 5,
      name: 'Friday'
    },
    {
      value: 6,
      name: 'Saturday'
    }
  ]
}

export const loginSteps = {
  login: 1,
  projectSelect: 2,
  securityCodeConfirm: 3
}

export const userType = {
  staff: '1',
  tenant: '2'
}

export const feePaymentStatusObject = {
  '1': 'FEE_UNPAID',
  '2': 'FEE_PAID',
  '3': 'FEE_REFUND'
}

export const listFeePaymentStatus = [
  {
    get label() {
      return L('ALL')
    },
    value: ''
  },
  {
    get label() {
      return L('FEE_UNPAID')
    },
    value: AppConsts.feePaymentStatus.unPaid
  },
  {
    get label() {
      return L('FEE_PAID')
    },
    value: AppConsts.feePaymentStatus.paid
  },
  {
    get label() {
      return L('FEE_REFUND')
    },
    value: AppConsts.feePaymentStatus.refund
  }
]

export const showToResidents = [
  {
    get label() {
      return L('ALL')
    },
    value: ''
  },
  {
    get label() {
      return L('YES')
    },
    value: 'true'
  },
  {
    get label() {
      return L('NO')
    },
    value: 'false'
  }
]

export const workflowEvent = {
  init: 'InitWorkflow'
}
export const documentTypes = {
  image: 'IMAGE',
  document: 'DOCUMENT'
}
export const cookieKeys = {
  encToken: 'enc_auth_token'
}
export const defaultAvatar = '/assets/images/logo.png'
export const dateFormat = 'DD/MM/YYYY'
export const dateTimeFormat = 'DD/MM/YYYY HH:mm'
export const yearFormat = 'YYYY'
export const timeFormat = 'HH:mm'
export const phoneRegex =
  /^[+]?\(?([0-9]{0,3})?\)?[-. ]?([0-9]{1,3})?[-. ]?([0-9]{1,3})[-. ]?([0-9]{1,5})$/
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const modules = [
  // {  get name() { return LCategory('MODULE_DEFAULT')},  id: 0 },
  // {  get name() { return LCategory('MODULE_OTHERS')},  id: 1 },
  // {  get name() { return LCategory('MODULE_NOTIFICATION_SYSTEM')},  id: 2 },
  // {  get name() { return LCategory('MODULE_USERS')},  id: 3 },
  // {  get name() { return LCategory('MODULE_STAFF')},  id: 4 },
  // {  get name() { return LCategory('MODULE_RESIDENT')},  id: 5 },
  // {  get name() { return LCategory('MODULE_TENANCY')},  id: 6 },
  // {  get name() { return LCategory('MODULE_PROVIDERS')},  id: 7 },
  // {  get name() { return LCategory('MODULE_PROJECT')},  id: 8 },
  // {  get name() { return LCategory('MODULE_BUILDING')},  id: 9 },
  // {  get name() { return LCategory('MODULE_FLOOR')},  id: 10 },
  // {  get name() { return LCategory('MODULE_UNIT')},  id: 11 },
  // {  get name() { return LCategory('MODULE_WORKFLOW')},  id: 12 },
  {
    get name() {
      return 'MODULE_WORKORDER' //LCategory('MODULE_WORKORDER')
    },
    id: 13
  },
  // {  get name() { return LCategory('MODULE_WORKORDERTASK')},  id: 14 },
  // {  get name() { return LCategory('MODULE_PLANMAINTENANCE')},  id: 15 },
  // {  get name() { return LCategory('MODULE_PLANMAINTENANCETASK')},  id: 16 },
  // {  get name() { return LCategory('MODULE_BOOKINGS')},  id: 17 },
  // {  get name() { return LCategory('MODULE_EVENTS')},  id: 18 },
  // {  get name() { return LCategory('MODULE_LIBRARIES')},  id: 19 },
  {
    get name() {
      return 'MODULE_FEEDBACK' //LCategory('MODULE_FEEDBACK')
    },
    id: 20
  }
  // {  get name() { return LCategory('MODULE_COMMUNICATION')},  id: 21 },
  // {  get name() { return LCategory('MODULE_SURVEY')},  id: 22 },
  // {  get name() { return LCategory('MODULE_ONLINEFORM')},  id: 23 },
  // {  get name() { return LCategory('MODULE_DELIVERIES')},  id: 24 },
  // {  get name() { return LCategory('MODULE_INSTRUCTIONS')},  id: 25 },
  // {  get name() { return LCategory('MODULE_INBOX')},  id: 26 },
  // {  get name() { return LCategory('MODULE_INQUIRY')},  id: 27 },
  // {  get name() { return LCategory('MODULE_VISITOR')},  id: 28 },
  // {  get name() { return LCategory('MODULE_AMENITY')},  id: 29 },
  // {  get name() { return LCategory('MODULE_AMENITYBLACKLIST')},  id: 30 },
  // {  get name() { return LCategory('MODULE_AMENITYGROUP')},  id: 31 },
  // {  get name() { return LCategory('MODULE_AMENITYMAINTENANCE')},  id: 32 },
  // {  get name() { return LCategory('MODULE_CARD')},  id: 33 },
  // {  get name() { return LCategory('MODULE_CARDPHYSICAL')},  id: 34 },
  // {  get name() { return LCategory('MODULE_CONTACT')},  id: 35 },
  // {  get name() { return LCategory('MODULE_CONTRACT')},  id: 36 },
  // {  get name() { return LCategory('MODULE_EMPLOYEE')},  id: 37 },
  // {  get name() { return LCategory('MODULE_NOTIFICATIONSETTING')},  id: 38 },
  // {  get name() { return LCategory('MODULE_INVENTORY')},  id: 39 },
  // {  get name() { return LCategory('MODULE_TEAM')},  id: 40 },
  // {  get name() { return LCategory('MODULE_INSPECTION')},  id: 41 },
  // {  get name() { return LCategory('MODULE_INSPECTIONTASK')},  id: 42 },
  // {  get name() { return LCategory('MODULE_ASSET')},  id: 43 },
  // {  get name() { return LCategory('MODULE_LEASECONTRACT')},  id: 44 }
]
export const moduleIds = {
  workOrder: 13,
  feedback: 20,
  unit: 1,
  unitEdit: 1001,
  comment: 1002,
  reservation: 17,
  visitor: 1003,
  planMaintenance: 18,
  inventory: 34,
  order: 47,
  inspection: 41,
  enquiry: 49,
  eform: 23,
  handover: 49
}
export const modulePrefix = {
  13: 'WORK_ORDER_WF_',
  20: 'FEEDBACK_WF_'
}
export const announcementTypeOptions = [
  {
    get label() {
      return L('EMAIL')
    },
    value: 2
  },
  {
    get label() {
      return L('INAPP')
    },
    value: 3
  }
]
export const moduleFile = {
  library: 'Library',
  project: 'Project',
  workOrder: 'WorkOrder',
  workOrderAfters: 'WorkOrderAfters',
  feedback: 'Feedback',
  feedbackAfters: 'FeedbackAfters',
  news: 'News',
  event: 'Event',
  reservation: 'Reservation',
  chatMessage: 'ChatMessages',
  amenity: 'Amenities',
  visitor: 'Visitor',
  company: 'Company',
  contract: 'Contract',
  contractCategory: 'ContractCategory',
  buildingDirectory: 'BuildingDirectory',
  planMaintenance: 'PlanMaintenance',
  asset: 'AssetManagement',
  shopOwner: 'ShopOwner',
  product: 'Product',
  inventory: 'Inventory',
  inventoryStockIn: 'InventoryStock',
  inventoryStockOut: 'InventoryAllocate'
}
export const notificationMethod = {
  1: 'SMS',
  2: 'EMAIL', //(allow HTML)
  3: 'INAPP'
}
export const getEscalationModuleByModuleId = (moduleId) => {
  switch (moduleId) {
    case moduleIds.inspection: {
      return 4
    }
    case moduleIds.planMaintenance: {
      return 3
    }
    case moduleIds.feedback: {
      return 2
    }
    case moduleIds.workOrder:
    default: {
      return 1
    }
  }
}
export const backgroundColors = [
  '#FAC51D',
  '#66BD6D',
  '#FAA026',
  '#29BB9C',
  '#E96B56',
  '#55ACD2',
  '#B7332F',
  '#2C83C9',
  '#9166B8',
  '#92E7E8'
]
export const getBackgroundColorByIndex = (arrayIndex) => {
  const index = arrayIndex % backgroundColors.length
  return backgroundColors[index]
}

export const moduleAvatar = {
  myProfile: 'myProfile',
  staff: 'Staff',
  resident: 'Resident',
  shopOwner: 'ShopOwner',
  project: 'Project',
  colorByLetter: (letter) => {
    if (!backgroundColors || !letter) return '#fff'

    const charCode = letter.charCodeAt(0)
    return getBackgroundColorByIndex(charCode)
  }
}
export const wfFieldTypes = {
  text: 0,
  number: 1,
  money: 2,
  dateTime: 3,
  list: 4
}

export const ckeditorToolbar = {
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'doctools', 'document', 'source'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing']
    },
    { name: 'styles', groups: ['styles', 'font-family'] },
    { name: 'forms', groups: ['forms'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['align', 'list', 'indent', 'blocks', 'bidi', 'paragraph']
    },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] }
  ],
  removeButtons:
    'Save,Templates,Cut,NewPage,Preview,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,ShowBlocks,About,Flash,PageBreak,HorizontalRule,Language,BidiRtl,BidiLtr,Blockquote,CreateDiv,Smiley,Iframe'
}
export const mimeType = {
  'application/pdf': PdfIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    ExcelIcon,
  'application/vnd.ms-excel': ExcelIcon,
  'application/msword': WordIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    WordIcon,
  'application/vnd.ms-powerpoint': PowerPointIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    PowerPointIcon,
  'image/jpeg': ImageIcon,
  'image/png': ImageIcon,
  other: OtherFileIcon
}
export const appPermissions = {
  appSetting: {
    page: 'PagesAdministration.Host.Settings'
  },
  communication: {
    page: 'PagesAdministration.Communication',
    read: 'PagesAdministration.Communication.Read',
    create: 'PagesAdministration.Communication.Create'
  },
  adminLanguage: {
    page: 'PagesAdministration.Languages',
    create: 'PagesAdministration.Languages.Create',
    read: 'PagesAdministration.Languages.Read',
    update: 'PagesAdministration.Languages.Update',
    delete: 'PagesAdministration.Languages.Delete',
    changeText: 'PagesAdministration.Languages.ChangeTexts'
  },
  adminRole: {
    page: 'PagesAdministration.Roles',
    create: 'PagesAdministration.Roles.Create',
    read: 'PagesAdministration.Roles.Read',
    update: 'PagesAdministration.Roles.Update',
    delete: 'PagesAdministration.Roles.Delete'
  },
  adminUser: {
    page: 'PagesAdministration.Users',
    create: 'PagesAdministration.Users.Create',
    read: 'PagesAdministration.Users.Read',
    update: 'PagesAdministration.Users.Update',
    delete: 'PagesAdministration.Users.Delete'
  },
  adminTenant: {
    page: 'PagesAdministration.Roles',
    create: 'PagesAdministration.Roles.Create',
    read: 'PagesAdministration.Roles.Read',
    update: 'PagesAdministration.Roles.Update',
    delete: 'PagesAdministration.Roles.Delete'
  },
  adminMasterData: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Detail'
  },
  workflow: {
    page: 'PagesAdministration.Workflow',
    create: 'PagesAdministration.Workflow.Create',
    read: 'PagesAdministration.Workflow.Read',
    update: 'PagesAdministration.Workflow.Update',
    delete: 'PagesAdministration.Workflow.Delete',
    detail: 'PagesAdministration.Workflow.Detail'
  },
  notificationTemplate: {
    page: 'PagesAdministration.NotificationTemplate',
    create: 'PagesAdministration.NotificationTemplate.Create',
    read: 'PagesAdministration.NotificationTemplate.Read',
    update: 'PagesAdministration.NotificationTemplate.Update',
    delete: 'PagesAdministration.NotificationTemplate.Delete',
    detail: 'PagesAdministration.NotificationTemplate.Detail'
  },
  dashboard: {
    page: 'PagesAdministration.Dashboard',
    fee: 'PagesAdministration.Dashboard.FeeStatement',
    workOrder: 'PagesAdministration.Dashboard.WorkOrder',
    report: 'PagesAdministration.Dashboard.Report',
    overview: 'PagesAdministration.Dashboard.Overview'
  },
  settingEmail: {
    page: 'PagesAdministration.SettingEmail'
  },
  staff: {
    page: 'PagesAdministration.Staff',
    create: 'PagesAdministration.Staff.Create',
    read: 'PagesAdministration.Staff.Read',
    update: 'PagesAdministration.Staff.Update',
    delete: 'PagesAdministration.Staff.Delete',
    detail: 'PagesAdministration.Staff.Detail'
  },
  resident: {
    page: 'PagesAdministration.Resident',
    create: 'PagesAdministration.Resident.Create',
    read: 'PagesAdministration.Resident.Read',
    update: 'PagesAdministration.Resident.Update',
    delete: 'PagesAdministration.Resident.Delete',
    detail: 'PagesAdministration.Resident.Detail',
    fullDetail: 'PagesAdministration.Resident.FullDetail',
    export: 'PagesAdministration.Resident.Export'
  },
  shopOwner: {
    page: 'PagesAdministration.ShopOwner',
    create: 'PagesAdministration.ShopOwner.Create',
    read: 'PagesAdministration.ShopOwner.Read',
    update: 'PagesAdministration.ShopOwner.Update',
    delete: 'PagesAdministration.ShopOwner.Delete',
    detail: 'PagesAdministration.ShopOwner.Detail',
    updateShopOwner: 'PagesAdministration.ShopOwner.UpdateShopOwner'
  },
  company: {
    page: 'PagesAdministration.Company',
    create: 'PagesAdministration.Company.Create',
    read: 'PagesAdministration.Company.Read',
    update: 'PagesAdministration.Company.Update',
    delete: 'PagesAdministration.Company.Delete',
    detail: 'PagesAdministration.Company.Detail'
  },
  companyContract: {
    page: 'PagesAdministration.Contract',
    create: 'PagesAdministration.Contract.Create',
    read: 'PagesAdministration.Contract.Read',
    update: 'PagesAdministration.Contract.Update',
    delete: 'PagesAdministration.Contract.Delete',
    detail: 'PagesAdministration.Contract.Detail'
  },
  contractCategory: {
    page: 'PagesAdministration.Contract',
    create: 'PagesAdministration.Contract.Create',
    read: 'PagesAdministration.Contract.Read',
    update: 'PagesAdministration.Contract.Update',
    delete: 'PagesAdministration.Contract.Delete',
    detail: 'PagesAdministration.Contract.Detail'
  },
  project: {
    page: 'PagesAdministration.Project',
    create: 'PagesAdministration.Project.Create',
    read: 'PagesAdministration.Project.Read',
    update: 'PagesAdministration.Project.Update',
    delete: 'PagesAdministration.Project.Delete',
    detail: 'PagesAdministration.Project.Detail'
  },
  building: {
    page: 'PagesAdministration.Building',
    create: 'PagesAdministration.Building.Create',
    read: 'PagesAdministration.Building.Read',
    update: 'PagesAdministration.Building.Update',
    delete: 'PagesAdministration.Building.Delete',
    detail: 'PagesAdministration.Building.Detail'
  },
  floor: {
    page: 'PagesAdministration.Floor',
    create: 'PagesAdministration.Floor.Create',
    read: 'PagesAdministration.Floor.Read',
    update: 'PagesAdministration.Floor.Update',
    delete: 'PagesAdministration.Floor.Delete',
    detail: 'PagesAdministration.Floor.Detail'
  },
  unit: {
    page: 'PagesAdministration.Unit',
    create: 'PagesAdministration.Unit.Create',
    read: 'PagesAdministration.Unit.Read',
    update: 'PagesAdministration.Unit.Update',
    delete: 'PagesAdministration.Unit.Delete',
    detail: 'PagesAdministration.Unit.Detail',
    export: 'PagesAdministration.Unit.Export',
    import: 'PagesAdministration.Unit.Import',
    moveIn: 'PagesAdministration.Unit.MoveIn',
    moveOut: 'PagesAdministration.Unit.MoveOut'
  },
  library: {
    page: 'PagesAdministration.Library',
    create: 'PagesAdministration.Library.Create',
    read: 'PagesAdministration.Library.Read',
    update: 'PagesAdministration.Library.Update',
    delete: 'PagesAdministration.Library.Delete'
  },
  workOrder: {
    page: 'PagesAdministration.Workorder',
    create: 'PagesAdministration.Workorder.Create',
    read: 'PagesAdministration.Workorder.Read',
    update: 'PagesAdministration.Workorder.Update',
    delete: 'PagesAdministration.Workorder.Delete',
    detail: 'PagesAdministration.Workorder.Detail',
    myWorkOrder: 'PagesAdministration.Workorder.MyWorkorder',
    config: 'PagesAdministration.WorkorderConfiguration',
    export: 'PagesAdministration.Workorder.Export'
  },
  feedback: {
    page: 'PagesAdministration.Feedback',
    create: 'PagesAdministration.Feedback.Create',
    read: 'PagesAdministration.Feedback.Read',
    update: 'PagesAdministration.Feedback.Update',
    delete: 'PagesAdministration.Feedback.Delete',
    detail: 'PagesAdministration.Feedback.Detail',
    myWorkOrder: 'PagesAdministration.Feedback.MyWorkorder',
    config: 'PagesAdministration.FeedbackConfiguration',
    export: 'PagesAdministration.Feedback.Export'
  },
  visitor: {
    page: 'PagesAdministration.Visitor',
    create: 'PagesAdministration.Visitor.Create',
    read: 'PagesAdministration.Visitor.Read',
    update: 'PagesAdministration.Visitor.Update',
    delete: 'PagesAdministration.Visitor.Delete',
    detail: 'PagesAdministration.Visitor.Detail',
    export: 'PagesAdministration.Visitor.Export'
  },
  buildingDirectory: {
    page: 'PagesAdministration.BuildingDirectory',
    create: 'PagesAdministration.BuildingDirectory.Create',
    read: 'PagesAdministration.BuildingDirectory.Read',
    update: 'PagesAdministration.BuildingDirectory.Update',
    delete: 'PagesAdministration.BuildingDirectory.Delete',
    detail: 'PagesAdministration.BuildingDirectory.Detail',
    export: 'PagesAdministration.BuildingDirectory.Export'
  },
  asset: {
    page: 'PagesAdministration.Asset',
    create: 'PagesAdministration.Asset.Create',
    read: 'PagesAdministration.Asset.Read',
    update: 'PagesAdministration.Asset.Update',
    delete: 'PagesAdministration.Asset.Delete',
    detail: 'PagesAdministration.Asset.Detail',
    import: 'PagesAdministration.Asset.Import',
    export: 'PagesAdministration.Asset.Export'
  },
  planMaintenance: {
    page: 'PagesAdministration.PlanMaintenance',
    create: 'PagesAdministration.PlanMaintenance.Create',
    read: 'PagesAdministration.PlanMaintenance.Read',
    update: 'PagesAdministration.PlanMaintenance.Update',
    delete: 'PagesAdministration.PlanMaintenance.Delete',
    detail: 'PagesAdministration.PlanMaintenance.Detail',
    export: 'PagesAdministration.PlanMaintenance.Export'
  },
  inventory: {
    page: 'PagesAdministration.Inventory',
    create: 'PagesAdministration.Inventory.Create',
    read: 'PagesAdministration.Inventory.Read',
    update: 'PagesAdministration.Inventory.Update',
    delete: 'PagesAdministration.Inventory.Delete',
    detail: 'PagesAdministration.Inventory.Detail',
    export: 'PagesAdministration.Inventory.Export'
  },
  news: {
    page: 'PagesAdministration.Event',
    create: 'PagesAdministration.Event.Create',
    read: 'PagesAdministration.Event.Read',
    update: 'PagesAdministration.Event.Update',
    delete: 'PagesAdministration.Event.Delete',
    detail: 'PagesAdministration.Event.Detail'
  },
  event: {
    page: 'PagesAdministration.Event',
    create: 'PagesAdministration.Event.Create',
    read: 'PagesAdministration.Event.Read',
    update: 'PagesAdministration.Event.Update',
    delete: 'PagesAdministration.Event.Delete',
    detail: 'PagesAdministration.Event.Detail'
  },
  feePackage: {
    page: 'PagesAdministration.FeePackage',
    create: 'PagesAdministration.FeePackage.Create',
    read: 'PagesAdministration.FeePackage.Read',
    update: 'PagesAdministration.FeePackage.Update',
    delete: 'PagesAdministration.FeePackage.Delete'
  },
  feeStatement: {
    page: 'PagesAdministration.FeeStatement',
    create: 'PagesAdministration.FeeStatement.Create',
    read: 'PagesAdministration.FeeStatement.Read',
    update: 'PagesAdministration.FeeStatement.Update',
    delete: 'PagesAdministration.FeeStatement.Delete',
    auditLog: 'PagesAdministration.FeeStatement.AuditLog',
    export: 'PagesAdministration.FeeStatement.Export'
  },
  feeReceipt: {
    page: 'PagesAdministration.FeeReceipt',
    create: 'PagesAdministration.FeeReceipt.Create',
    read: 'PagesAdministration.FeeReceipt.Read',
    update: 'PagesAdministration.FeeReceipt.Update',
    delete: 'PagesAdministration.FeeReceipt.Delete'
  },
  feeVoucher: {
    page: 'PagesAdministration.FeeReceipt',
    create: 'PagesAdministration.FeeReceipt.Create',
    read: 'PagesAdministration.FeeReceipt.Read',
    update: 'PagesAdministration.FeeReceipt.Update',
    delete: 'PagesAdministration.FeeReceipt.Delete'
  },
  feeType: {
    page: 'PagesAdministration.FeeType',
    create: 'PagesAdministration.FeeType.Create',
    read: 'PagesAdministration.FeeType.Read',
    update: 'PagesAdministration.FeeType.Update',
    delete: 'PagesAdministration.FeeType.Delete'
  },
  amenity: {
    page: 'PagesAdministration.Amenity',
    create: 'PagesAdministration.Amenity.Create',
    read: 'PagesAdministration.Amenity.Read',
    update: 'PagesAdministration.Amenity.Update',
    delete: 'PagesAdministration.Amenity.Delete',
    detail: 'PagesAdministration.Amenity.Detail'
  },
  amenityMonthlyPackage: {
    page: 'PagesAdministration.MonthlyPackage',
    create: 'PagesAdministration.MonthlyPackage.Create',
    read: 'PagesAdministration.MonthlyPackage.Read',
    update: 'PagesAdministration.MonthlyPackage.Update',
    delete: 'PagesAdministration.MonthlyPackage.Delete',
    detail: 'PagesAdministration.MonthlyPackage.Detail',
    export: 'PagesAdministration.MonthlyPackage.Export'
  },
  amenityBlacklist: {
    page: 'PagesAdministration.AmenityBlackList',
    create: 'PagesAdministration.AmenityBlackList.Create',
    read: 'PagesAdministration.AmenityBlackList.Read',
    update: 'PagesAdministration.AmenityBlackList.Update',
    delete: 'PagesAdministration.AmenityBlackList.Delete',
    detail: 'PagesAdministration.AmenityBlackList.Detail'
  },
  amenityGroup: {
    page: 'PagesAdministration.AmenityGroup',
    create: 'PagesAdministration.AmenityGroup.Create',
    read: 'PagesAdministration.AmenityGroup.Read',
    update: 'PagesAdministration.AmenityGroup.Update',
    delete: 'PagesAdministration.AmenityGroup.Delete',
    detail: 'PagesAdministration.AmenityGroup.Detail'
  },
  amentylimited: {
    page: 'PagesAdministration.Workorder',
    create: 'PagesAdministration.Workorder.Create',
    read: 'PagesAdministration.Workorder.Read',
    update: 'PagesAdministration.Workorder.Update',
    delete: 'PagesAdministration.Workorder.Delete',
    detail: 'PagesAdministration.Workorder.Detail'
  },
  reservation: {
    page: 'PagesAdministration.Reservation',
    create: 'PagesAdministration.Reservation.Create',
    read: 'PagesAdministration.Reservation.Read',
    update: 'PagesAdministration.Reservation.Update',
    delete: 'PagesAdministration.Reservation.Delete',
    detail: 'PagesAdministration.Reservation.Detail',
    export: 'PagesAdministration.Reservation.Detail',
    import: 'PagesAdministration.Reservation.Detail'
  },
  announcement: {
    page: 'PagesAdministration.Announcement',
    create: 'PagesAdministration.Announcement.Create',
    read: 'PagesAdministration.Announcement.Read',
    update: 'PagesAdministration.Announcement.Update',
    delete: 'PagesAdministration.Announcement.Delete',
    detail: 'PagesAdministration.Announcement.Detail'
  },
  system: {
    paymentSetting: 'PagesAdministration.System.Settings'
  },
  product: {
    page: 'PagesAdministration.Product',
    create: 'PagesAdministration.Product.Create',
    read: 'PagesAdministration.Product.Read',
    update: 'PagesAdministration.Product.Update',
    delete: 'PagesAdministration.Product.Delete',
    detail: 'PagesAdministration.Product.Detail'
  },
  eOrders: {
    page: 'PagesAdministration.EOrders',
    create: 'PagesAdministration.EOrders.Create',
    read: 'PagesAdministration.EOrders.Read',
    update: 'PagesAdministration.EOrders.Update',
    delete: 'PagesAdministration.EOrders.Delete',
    detail: 'PagesAdministration.EOrders.Detail'
  },
  enquiry: {
    page: 'PagesAdministration.Enquiry',
    create: 'PagesAdministration.Enquiry.Create',
    read: 'PagesAdministration.Enquiry.Read',
    update: 'PagesAdministration.Enquiry.Update',
    delete: 'PagesAdministration.Enquiry.Delete',
    detail: 'PagesAdministration.Enquiry.Detail'
  },
  handoverPlan: {
    page: 'PagesAdministration.HandoverPlan',
    create: 'PagesAdministration.HandoverPlan.Create',
    read: 'PagesAdministration.HandoverPlan.Read',
    update: 'PagesAdministration.HandoverPlan.Update',
    delete: 'PagesAdministration.HandoverPlan.Delete',
    detail: 'PagesAdministration.HandoverPlan.Detail'
  },
  handoverReservation: {
    page: 'PagesAdministration.HandoverReservation',
    create: 'PagesAdministration.HandoverReservation.Create',
    read: 'PagesAdministration.HandoverReservation.Read',
    update: 'PagesAdministration.HandoverReservation.Update',
    delete: 'PagesAdministration.HandoverReservation.Delete',
    detail: 'PagesAdministration.HandoverReservation.Detail'
  },
  eForm: {
    page: 'PagesAdministration.EForm',
    create: 'PagesAdministration.EForm.Create',
    read: 'PagesAdministration.EForm.Read',
    update: 'PagesAdministration.EForm.Update',
    delete: 'PagesAdministration.EForm.Delete',
    detail: 'PagesAdministration.EForm.Detail'
  },
  eFormAnswer: {
    page: 'PagesAdministration.EFormAnswer',
    create: 'PagesAdministration.EFormAnswer.Create',
    read: 'PagesAdministration.EFormAnswer.Read',
    update: 'PagesAdministration.EFormAnswer.Update',
    delete: 'PagesAdministration.EFormAnswer.Delete',
    detail: 'PagesAdministration.EFormAnswer.Detail'
  }
}

// Notification
export const notificationTypes = {
  text: 1,
  download: 2,
  gotoDetail: 3
}

// Layout constant
export const themeByEvent = {
  events: {
    default: 'default',
    xmasSanta: 'xmas-santa',
    xmasHouse: 'xmas-house',
    xmasNight: 'xmas-night',
    flowers: 'flowers'
  }
}

export enum CalendarTypes {
  PlanMaintenance = 1
}

export const fileTypeGroup = {
  pdf: ['.pdf'],
  office: ['.xlsx', '.doc', '.docx'],
  images: ['.png', '.jpg', '.jpeg', '.PNG', '.JPG', '.JPEG'],
  documentNotExcel: ['.pdf', '.doc', '.docx'],
  documents: ['.csv', '.xlsx', '.pdf', '.doc', '.docx'],
  documentAndImage: [
    '.csv',
    '.xlsx',
    '.xsl',
    '.ppt',
    '.pptx',
    '.pdf',
    '.doc',
    '.docx',
    '.png',
    '.jpg',
    '.jpeg',
    '.JPG',
    '.PNG',
    '.JPEG'
  ],
  video: ['.mp4'],
  videos: ['.mp4', '.3gp', '.mov']
}

export const QUESTION_TYPES = {
  1: { id: 1, name: 'Multiple Choice', hasOptions: true },
  2: { id: 2, name: 'Dropdown', hasOptions: true },
  3: { id: 3, name: 'Textbox' },
  4: { id: 4, name: 'Textarea' },
  5: { id: 5, name: 'Date/Time' },
  6: { id: 6, name: 'Number' },
  7: { id: 7, name: 'Rating' },
  8: { id: 8, name: 'Photo' },
  9: { id: 9, name: 'Label' }
}

export const QUESTION_VIEW_MODE = {
  editQuestion: 1,
  viewResponse: 2
}

export const EFORM_PUBLISH_STATUS = {
  PUBLISHED: 'FORMPUBLIC',
  UNPUBLIC: 'FORMUNPUBLIC'
}

export const activeStatusRenderTag = {
  active: {
    id: 1,
    name: L('ACTIVE'),
    colorCode: '#64AA4D',
    borderColorCode: '#64AA4D1F'
  },
  inactive: {
    id: 0,
    name: L('INACTIVE'),
    colorCode: '#AAAAAA',
    borderColorCode: '#AAAAAA1F'
  }
}

export const rangePickerPlaceholder: any = () => {
  const label = [
    {
      get label() {
        return L('START_DATE')
      }
    },
    {
      get label() {
        return L('END_DATE')
      }
    }
  ]
  return label.map((i: any) => i.label)
}

export default AppConsts
